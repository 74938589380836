<template>
  <div class="login-content">
    <ProductPdf v-if="ready" :show="true" :file="file" />
  </div>
</template>
<script>
import api from "@/lib/api";
import ProductPdf from "@/components/ProductPdf.vue";

export default {
  name: "Download",
  components: {
    ProductPdf,
  },
  data() {
    return {
      csrf: "",
      ready: false,
      file: "",
    };
  },
  beforeCreate() {},
  mounted() {
    this.file = this.$route.params.product;
    api
      .get("/api/login")
      .then((response) => {
        console.log("res", response);
        this.csrf = response.data.csrfToken;
        this.getProduct();
      })
      .catch((errors) => {
        console.log(errors.response);
        console.log(errors.response.data.error);
      });
  },
  methods: {
    getProduct() {
      let data = {
        slug: this.$route.params.product,
        _csrf: this.csrf,
      };
      api
        .post("/api/download", data)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            console.log(response.data.product);
            this.$store.commit("saveProduct", response.data.product);
            this.ready = true;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
};
</script>
